// extracted by mini-css-extract-plugin
export var bg = "PartnerWithUsForm-module--bg--eed89";
export var checked = "PartnerWithUsForm-module--checked--d8156";
export var contactForm = "PartnerWithUsForm-module--contact-form--0e9af";
export var contactLabel = "PartnerWithUsForm-module--contact-label--dac13";
export var customCheckbox = "PartnerWithUsForm-module--custom-checkbox--f4055";
export var formContent = "PartnerWithUsForm-module--form-content--27157";
export var formShortcode = "PartnerWithUsForm-module--form-shortcode--caa09";
export var formText = "PartnerWithUsForm-module--form-text--84d5e";
export var thankYou = "PartnerWithUsForm-module--thank-you--5c60e";
export var title = "PartnerWithUsForm-module--title--e1694";
export var titleBg = "PartnerWithUsForm-module--title-bg--a4e12";
export var wpcf7Spinner = "PartnerWithUsForm-module--wpcf7-spinner--88239";