
import axios from "axios";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import React, { useEffect, useRef } from "react";
import { useInViewport } from 'react-in-viewport';
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import * as s from "./PartnerWithUsForm.module.css";

const config = {
  baseURL: "https://webhook.site/427d7f1c-5c86-498c-b25a-4f5f31afd6a2",
  headers: { "Content-Type": "application/json" },
  method: "post",
  timeout: 3000,
};
const instance = axios.create(config);

const PartnerWithUsForm = ({ block }) => {

  const blockRef = useRef();
  const { enterCount } = useInViewport(blockRef, {}, { disconnectOnLeave: false }, {});

  const form = useRef();
  const [name, setName] = React.useState("");
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  // const [jobtitle, setJobTitle] = React.useState("");
  const [businessName, setBusinessName] = React.useState("");
  // const [businesstype, setBusinesstype] = React.useState("");
  const [partnershiptype, setPartnershipType] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [messageSent, setMessageSent] = React.useState(false);


  useEffect(() => {
    setName(`${firstName} ${lastName}`);
  }, [firstName, lastName])

  const [
    iConcentToReceiveTextNotifications,
    setIConcentToReceiveTextNotifications,
  ] = React.useState(true);
  const [
    iConcentToReceiveTextNotificationsText,
    setConcentToReceiveTextNotificationsText,
  ] = React.useState("Yes");

  const [
    textRemindersText,
    setTextRemindersText,
  ] = React.useState("Yes");

  const handleSubmit = event => {
    event.preventDefault();
    const queryParams = JSON.parse(sessionStorage.getItem("queryParams"));
    const lines = [];
    try {
      for (var x in queryParams) {
        lines.push(`*${x}:* ${queryParams[x]}`);
      }
    } catch (e) { }

    const payloadForm = {
      event: "lead",
      source: "orders.co",
      utm_source: queryParams?.utm_source || "",
      name: !!name ? name : "",
      firstName: firstName,
      lastName: lastName,
      email: !!email ? email : "",
      phone: !!phone ? phone : "",
      // jobtitle: !!jobtitle ? jobtitle : "",
      businessName: !!businessName ? businessName : "",
      // businesstype: !!businesstype ? businesstype : "",
      partnershiptype: !!partnershiptype ? partnershiptype : "",
      
      iConcentToReceiveTextNotifications: iConcentToReceiveTextNotifications
        ? "Yes"
        : "No",
      textReminders: textRemindersText,
    };
    const payload = {
      ...payloadForm,
      details: lines.join("\n"),
    };
    instance
      .request({ data: JSON.stringify(payload) })
      .then(function (response) {
        // handle success
        console.log("Form1 Was Submitted Successfully");
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        window.dataLayer.push({ ...payloadForm });
        setName("");
        setEmail("");
        // setJobTitle("");
        setBusinessName("");
        // setBusinesstype("");
        setPartnershipType("");
        setPhone("");
        setMessageSent(true);
        // always executed
      });
  };

  const thankYouImage = {
    data: block?.thankYou?.image?.localFile?.childImageSharp?.gatsbyImageData,
    alt: block?.thankYou?.image?.altText || ``,
  }

  return (
    <section className={s.contactForm} id="contact-form" ref={blockRef}>
      {enterCount > 0 &&
        <div className="container">
          <div className={`${s.formContent} row align-items-center justify-content-center`}>
            <div className="col-12 d-flex flex-column justify-content-center">
              {!!block?.title &&
                <h2 className={`gradient-title ${s.title}`}>
                  {parse(block?.title)}
                </h2>
              }
            </div>
            <div className="col-12 col-md-8 col-xl-5 d-flex flex-column justify-content-center px-0">
              <div className={`${s.formShortcode} text-lg-center`}>
                {messageSent === false && (
                  <form
                    onSubmit={handleSubmit}
                    ref={form}
                    className="wpcf7-form init"
                    data-status="init"
                  >
                    <div className="row">
                      <div className="col-lg-6">
                        <label>
                          <span className="pl-2">First Name</span>
                          <br />
                          <span className="wpcf7-form-control-wrap customer-name mt-2 d-block">
                            <input
                              type="text"
                              name="firstName"
                              value={firstName}
                              onChange={e => setFirstName(e.target.value)}
                              size="40"
                              required
                              pattern="^(?!\s*$).+"
                            />
                          </span>
                        </label>
                      </div>
                      <div className="col-lg-6">
                        <label>
                          <span className="pl-2">Last Name</span>
                          <br />
                          <span className="wpcf7-form-control-wrap customer-name mt-2 d-block">
                            <input
                              type="text"
                              name="lastName"
                              value={lastName}
                              onChange={e => setLastName(e.target.value)}
                              size="40"
                              required
                              pattern="^(?!\s*$).+"
                            />
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 mt-3">
                        <label>
                          <span className="pl-2">Email</span>
                          <br />
                          <span className="wpcf7-form-control-wrap customer-email mt-2 d-block">
                            <input
                              type="email"
                              name="email"
                              size="40"
                              value={email}
                              onChange={e => setEmail(e.target.value)}
                              required
                            />
                          </span>
                        </label>
                      </div>
                      <div className="col-lg-6 mt-3">
                        <label>
                          <span className="pl-2">Phone Number</span>
                          <span className="wpcf7-form-control-wrap customer-phone mt-2 d-block rounded">
                            <PhoneInput
                              defaultCountry="US"
                              value={phone}
                              onChange={setPhone}
                              countrySelectProps={{
                                display: 'none'
                              }}
                              className="mb-0"
                              required
                              name="phone"
                            />
                          </span>
                        </label>
                      </div>
                    </div>
                    {/* <div className="row">
                      <div className="col-12 mt-3">
                        <label>
                          <span className="pl-2">What's Your Job Title?</span>
                          <br />
                          <span className="wpcf7-form-control-wrap customer-job-title mt-2 d-block">
                            <input
                              type="text"
                              name="jobtitle"
                              size="40"
                              value={jobtitle}
                              onChange={e => setJobTitle(e.target.value)}
                              required
                              pattern="^(?!\s*$).+"
                            />
                          </span>
                        </label>
                      </div>
                    </div> */}
                    <div className="row">
                      <div className="col-12 mt-3">
                        <label>
                          <span className="pl-2">Restaurant or Company Name</span>
                          <br />
                          <span className="wpcf7-form-control-wrap customer-business mt-2 d-block">
                            <input
                              type="text"
                              name="businessName"
                              size="40"
                              value={businessName}
                              onChange={e => setBusinessName(e.target.value)}
                              required
                              pattern="^(?!\s*$).+"
                            />
                          </span>
                        </label>
                      </div>
                    </div>
                    {/* <div className="row">
                      <div className="col-12 mt-3">
                        <label>
                          <span className="pl-2">Business Type</span>
                          <br />
                          <span className="wpcf7-form-control-wrap customer-business-type mt-2 d-block">
                            <select
                              name="businesstype"
                              value={businesstype}
                              onChange={e => setBusinesstype(e.target.value)}
                              required
                            >
                              <option value="">Select</option>
                              <option value="Single Location">Single Location</option>
                              <option value="Multi-Location">Multi-Location</option>
                              <option value="Franchise">Franchise</option>
                              <option value="Ghost Kitchen">Ghost Kitchen</option>
                              <option value="Convenience Store">Convenience Store</option>
                              <option value="Bar &amp; Brewery">Bar &amp; Brewery</option>
                              <option value="Hotel &amp; Resort">Hotel &amp; Resort</option>
                              <option value="Reseller">Reseller</option>
                              <option value="Virtual Brand Licensor">Virtual Brand Licensor</option>
                              <option value="Other">Other</option>
                            </select>
                          </span>
                        </label>
                      </div>
                    </div> */}
                    <div className="row">
                      <div className="col-12 mt-3">
                        <label>
                          <span className="pl-2">Type of Partnership</span>
                          <br />
                          <span className="wpcf7-form-control-wrap customer-business-type mt-2 d-block">
                            <select
                              name="partnershiptype"
                              value={partnershiptype}
                              onChange={e => setPartnershipType(e.target.value)}
                              required
                            >
                              <option value="">Select</option>
                              <option value="Resellers">Resellers</option>
                              <option value="Marketing and Media Partners">Marketing and Media Partners</option>
                              <option value="Industry Collaborators">Industry Collaborators</option>
                              <option value="Business Consultants">Business Consultants</option>
                              <option value="Technology Integration Partners">Technology Integration Partners</option>
                              <option value="Other">Other</option>
                            </select>
                          </span>
                        </label>
                      </div>
                    </div>

                    

                    <div className="mb-4 mt-4 d-flex justify-content-center">
                      <label
                        className="d-flex align-items-center cursor-pointer"
                        style={{ gap: "8px" }}
                      >
                        <input
                          type="hidden"
                          value={iConcentToReceiveTextNotificationsText}
                          name="iConcentToReceiveTextNotifications"
                        />
                        <input
                          type="hidden"
                          value={textRemindersText}
                          name="textReminders"
                        />
                        <input
                          type="checkbox"
                          className="cursor-pointer d-none"
                          onChange={e => {
                            setIConcentToReceiveTextNotifications(
                              e.target.checked
                            );
                            setConcentToReceiveTextNotificationsText(
                              e.target.checked ? "Yes" : "No"
                            );
                            setTextRemindersText(
                              e.target.checked ? "Yes" : "No"
                            );
                          }}
                          checked={iConcentToReceiveTextNotifications}
                        />
                        <span className={`${s.customCheckbox} ${iConcentToReceiveTextNotifications ? s.checked : ''}`}></span>
                        {/* Text me with updates about this meeting */}
                        Text me meeting reminders
                      </label>
                    </div>

                    <label>
                      <input
                        type="submit"
                        // value="Schedule A Free Demo"
                        value="Schedule A Call"
                        className="wpcf7-form-control has-spinner wpcf7-submit"
                      />
                      <span className="wpcf7-spinner"></span>
                    </label>
                    <div
                      className="wpcf7-response-output"
                      aria-hidden="true"
                    ></div>
                  </form>
                )}
                {messageSent === true && (
                  <div className="d-flex flex-column justify-content-center align-items-center w-100 h-100">
                    {!!thankYouImage?.data
                      ? <GatsbyImage
                        placeholder="none"
                        loading="eager"
                        image={thankYouImage.data}
                        alt={thankYouImage.alt}
                        className="mw-100" />
                      : <StaticImage
                        placeholder="none"
                        loading="eager"
                        alt="thank_you"
                        src="../../../images/thank_you.png"
                      />
                    }
                    <div className={`${s.thankYou} text-center`}>
                      {!!block?.thankYou?.text
                        ? <>{parse(block?.thankYou?.text)}</>
                        : <>Thank you! <br /> You are all set!</>
                      }
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      }
    </section>
  );
};

export default PartnerWithUsForm;
