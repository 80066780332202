const makeZapierRequest = (payload, webhook) => {

    if (!!sessionStorage.utm_source) {
        Object.keys(sessionStorage)?.forEach((key, index) => {
            if (key.indexOf('utm_') > -1) {
                payload[key] = sessionStorage[key];
            }
        });
    }
    // window.dataLayer.push({ ...payload });

    const requestOptions = {
        method: "POST",
        mode: "no-cors",
        headers: {
            "Content-Type": "application/json",
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify(payload),
    };
    if (!!webhook) {
        try {
            fetch(webhook, requestOptions);
        } catch (error) {
            console.log(error)
        }
    } else {
        // console.log('webhook is empty');
    }

}

export default makeZapierRequest;