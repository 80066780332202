
import { Link } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import React from "react";
import YoutubeVideo from './../../youtubeVideo/youtubeVideo';
import * as s from "./heroAlt.module.css";

const HeroAlt = ({ block }) => {

  const bp = useBreakpoint();

  const desktopImage = {
    data: block.desktopImage?.localFile?.childImageSharp?.gatsbyImageData,
    alt: block.desktopImage?.altText || ``,
  };

  const desktopGatsbyImage = !!desktopImage.data
    ? <GatsbyImage
      placeholder="none"
      loading="eager"
      image={desktopImage.data}
      alt={desktopImage.alt}
      className="mw-100" />
    : null;


  return (
    <>
      <section className={s.hero}>
        <div className="container">
          {block?.topLinks?.length > 0 && <div className={s.topLinks}>
            {block?.topLinks?.map((item, i) => {
              if (item.current) {
                return <span className={`${s.topLink} ${s.current}`} key={i}>{parse(item.label)}</span>
              } else {
                return <Link to={item?.link?.url} target={item?.link?.target} className={`${s.topLink}`} key={i}>{parse(item?.link?.title)}</Link>
              }
            })}
          </div>}
          <div className='row align-items-center'>
            <div className='col-lg-7 mb-3 mb-lg-0'>
              {!!block.title && <>
                {!!block?.useH1
                  ? <h1 className={`gradient-title ${s.title}`}>
                    {parse(block.title)}
                  </h1>
                  : <h2 className={`gradient-title ${s.title}`}>
                    {parse(block.title)}
                  </h2>
                }
              </>}
              {!!block?.description && <div className={s.description}>{parse(block?.description)}</div>}
              {!!block?.link?.url && <div className="justify-content-center mt-3 d-none d-lg-block">
                <Link to={block?.link?.url} target={block?.link?.target} className={s.ctaBtn}>{block?.link?.title}</Link>
              </div>}
            </div>
            <div className='col'>
              {(desktopGatsbyImage || block.youtubeVideoId) && <div className="w-100 position-relative" style={{ minHeight: '140px' }}>
                <div className='w-100 text-right'>
                  {!!block.youtubeVideoId
                    ? <YoutubeVideo
                      videoId={block.youtubeVideoId}
                      image={desktopGatsbyImage}
                    >
                    </YoutubeVideo>
                    : desktopGatsbyImage
                  }
                </div>
              </div>}
              {!!block?.link?.url && <div className="d-flex justify-content-center mt-3 d-lg-none">
                <Link to={block?.link?.url} target={block?.link?.target} className={s.ctaBtn}>{block?.link?.title}</Link>
              </div>}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HeroAlt;
